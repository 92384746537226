import { computed, inject, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { patchState, signalState } from '@ngrx/signals';
import { buffer, filter, map, tap } from 'rxjs';

export type StripesStateType = {
  readonly isStripesVisibleBySet: boolean;
  readonly isStripesVisibleByRoute: boolean;
};

const initialStripesState: StripesStateType = {
  isStripesVisibleBySet: false,
  isStripesVisibleByRoute: false,
};

@Injectable({
  providedIn: 'root',
})
export class StripesState {
  readonly #stripState = signalState<StripesStateType>(initialStripesState);
  readonly #router = inject(Router);

  readonly #navigationEnd$ = this.#router.events.pipe(filter((e) => e instanceof NavigationEnd));
  readonly #isStripesVisible = toSignal(
    this.#router.events.pipe(
      filter((e) => e instanceof ActivationEnd),
      buffer(this.#navigationEnd$),
      map(([leafNode]) => !!(leafNode as ActivationEnd).snapshot.data['isStripesVisible']),
      tap((isStripesVisibleByRoute) => patchState(this.#stripState, { isStripesVisibleByRoute }))
    )
  );

  constructor() {
    this.#isStripesVisible();
  }

  get isStripesVisible(): Signal<boolean> {
    return computed(() => this.#stripState.isStripesVisibleByRoute() || this.#stripState.isStripesVisibleBySet());
  }

  set isStripesVisible(isStripesVisibleBySet: boolean) {
    patchState(this.#stripState, { isStripesVisibleBySet });
  }
}
